<template>
  <b-card title="Listar Moedas">
    <listarMoeda />

  </b-card>
</template>

<script>
// import axios from 'axios'

import { BCard } from 'bootstrap-vue'
import listarMoeda from '../../Listagens/Moedas/ListarMoedas.vue'

export default {
  components: {
    BCard, listarMoeda,
  },
}
</script>
